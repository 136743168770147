<template>
  <div id="materialPurchaseStock">
    <el-dialog
      :title="materialPurchaseStockFormTitle"
      width="680px"
      :visible.sync="materialPurchaseStockDialogVisible"
      :close-on-click-modal="false"
      @close="materialPurchaseStockDialogClose"
    >
      <el-form
        ref="materialPurchaseStockFormRef"
        :model="materialPurchaseStockForm"
        :rules="materialPurchaseStockFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料编号" prop="materialNo">
              <el-input
                v-model="materialPurchaseStockForm.materialNo"
                placeholder="请输入物料编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="productName">
              <el-input
                v-model="materialPurchaseStockForm.productName"
                placeholder="请输入名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="materialPurchaseStockForm.spec"
                placeholder="请输入型号规格"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂家" prop="manufactor">
              <el-input
                v-model="materialPurchaseStockForm.manufactor"
                placeholder="请输入厂家"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input
                v-model="materialPurchaseStockForm.unit"
                placeholder="请输入单位"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价" prop="unitPrice">
              <el-input
                v-model="materialPurchaseStockForm.unitPrice"
                placeholder="请输入单价"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最低库存" prop="minStock">
              <el-input
                v-model="materialPurchaseStockForm.minStock"
                placeholder="请输入最低库存"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="materialPurchaseStockForm.remarks"
                placeholder="请输入备注"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialPurchaseStockDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialPurchaseStockFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料编号">
        <el-input
          v-model="searchForm.materialNo"
          placeholder="请输入物料编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_PURCHASE_STOCK_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialPurchaseStockPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialNo" label="物料编号" />
      <el-table-column prop="productName" label="名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="manufactor" label="厂家" />
      <el-table-column prop="unit" label="单位" />
      <!-- <el-table-column prop="unitPrice" label="单价" /> -->
      <el-table-column prop="minStock" label="最低库存" />
      <el-table-column prop="realStock" label="实际库存">
        <template slot-scope="scope">
          <span
            :style="{
              color: scope.row.realStock < scope.row.minStock ? 'red' : '#000',
            }"
          >{{ scope.row.realStock }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PURCHASE_STOCK_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PURCHASE_STOCK_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialPurchaseStockPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialPurchaseStock,
  deleteMaterialPurchaseStock,
  updateMaterialPurchaseStock,
  selectMaterialPurchaseStockInfo,
  selectMaterialPurchaseStockList
} from '@/api/storage/materialPurchaseStock'

export default {
  data () {
    return {
      materialPurchaseStockDialogVisible: false,
      materialPurchaseStockFormTitle: '',
      materialPurchaseStockForm: {
        id: '',
        materialNo: '',
        productName: '',
        spec: '',
        manufactor: '',
        unit: '',
        minStock: '',
        remarks: '',
        type: 1,
        unitPrice: ''
      },
      materialPurchaseStockFormRules: {
        materialNo: [
          {
            required: true,
            message: '物料编号不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      materialPurchaseStockPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialNo: '',
        type: 1
      }
    }
  },
  created () {
    selectMaterialPurchaseStockList(this.searchForm).then((res) => {
      this.materialPurchaseStockPage = res
    })
  },
  methods: {
    materialPurchaseStockDialogClose () {
      this.$refs.materialPurchaseStockFormRef.resetFields()
    },
    materialPurchaseStockFormSubmit () {
      if (this.materialPurchaseStockFormTitle === '原材料管理详情') {
        this.materialPurchaseStockDialogVisible = false
        return
      }
      this.$refs.materialPurchaseStockFormRef.validate(async (valid) => {
        if (valid) {
          if (this.materialPurchaseStockFormTitle === '新增原材料管理') {
            await addMaterialPurchaseStock(this.materialPurchaseStockForm)
          } else if (this.materialPurchaseStockFormTitle === '修改原材料管理') {
            await updateMaterialPurchaseStock(this.materialPurchaseStockForm)
          }
          this.materialPurchaseStockPage
            = await selectMaterialPurchaseStockList(this.searchForm)
          this.materialPurchaseStockDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialPurchaseStockFormTitle = '新增原材料管理'
      this.materialPurchaseStockDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialPurchaseStock(row.id)
        if (
          this.materialPurchaseStockPage.list.length === 1
          && this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--
        }
        this.materialPurchaseStockPage = await selectMaterialPurchaseStockList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialPurchaseStockFormTitle = '修改原材料管理'
      this.materialPurchaseStockDialogVisible = true
      this.selectMaterialPurchaseStockInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialPurchaseStockFormTitle = '原材料管理详情'
      this.materialPurchaseStockDialogVisible = true
      this.selectMaterialPurchaseStockInfoById(row.id)
    },
    selectMaterialPurchaseStockInfoById (id) {
      selectMaterialPurchaseStockInfo(id).then((res) => {
        this.materialPurchaseStockForm.id = res.id
        this.materialPurchaseStockForm.materialNo = res.materialNo
        this.materialPurchaseStockForm.productName = res.productName
        this.materialPurchaseStockForm.spec = res.spec
        this.materialPurchaseStockForm.manufactor = res.manufactor
        this.materialPurchaseStockForm.unit = res.unit
        this.materialPurchaseStockForm.minStock = res.minStock
        this.materialPurchaseStockForm.remarks = res.remarks
        this.materialPurchaseStockForm.unitPrice = res.unitPrice
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialPurchaseStockList(this.searchForm).then((res) => {
        this.materialPurchaseStockPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialPurchaseStockList(this.searchForm).then((res) => {
        this.materialPurchaseStockPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialPurchaseStockList(this.searchForm).then((res) => {
        this.materialPurchaseStockPage = res
      })
    }
  }
}
</script>

<style>
</style>
